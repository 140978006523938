<template>
	<div class="">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<h4>Activate employee credit line</h4>
								<p class="text-muted">
									Credit line allows employers make available a maximum amount of money
									that there employee can spend on transport within a month
								</p>
							</div>
						</div>

						<div class="alert alert-danger" v-if="error">
							<span>{{ error }}</span>
						</div>

						<form @submit.prevent="createCreditLine">
							<div class="form-group">
								<label for="">How much credit line do you want to give each employee?</label>
								<input v-model.number="form.amount" required type="number" name="amount" id="amount"
									class="form-control" />
							</div>

							<div class="form-group">
								<label for="">Which employees do you want to apply the credit to?</label>
								<select v-model="form.applicable_employee_kind" required name="applicable_employee_kind"
									id="applicable_employee_kind" class="custom-select">
									<option value="all">All Employees</option>
									<option value="all_except">All employees except some</option>
									<option value="some">Only some select employees</option>
								</select>
							</div>

							<div class="form-group" v-if="form.applicable_employee_kind != 'all'">
								<label for="">Select the Employees
									{{
										form.applicable_employee_kind == "all_except"
											? "to exempt"
											: "to include"
									}}</label>
								<multiselect v-model="form.applicable_employee_value" id="ajax" :custom-label="fullName" track-by="id"
									placeholder="Type to search" open-direction="bottom" :options="staffList" :multiple="true"
									:searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
									:close-on-select="false" :options-limit="300" :limit="10" :limit-text="limitText"
									:show-no-results="false" :hide-selected="true" @search-change="asyncFindEmployee">
									<template slot="clear" slot-scope="props">
										<div class="multiselect__clear" v-if="form.applicable_employee_value.length"
											@mousedown.prevent.stop="clearAll(props.search)"></div>
									</template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
								</multiselect>
							</div>

							<div class="form-group">
								<label for="apply_immediately">
									<input type="checkbox" id="apply_immediately" name="apply_immediately" v-model="form.apply_immediately"
										class="custom-checkbox" />
									Apply credit line to staff immediately
								</label>
							</div>

							<div class="form-group">
								<button type="submit" class="btn btn-dark" :disabled="creating">
									{{ creating ? "Creating.." : "Activate Credit Line" }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { axiosInstance } from '@/plugins/axios'
import Multiselect from 'vue-multiselect'
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router/composables'

const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})


const error = ref(null)
const form = ref({
  apply_immediately: true,
  applicable_employee_kind: 'all',
  applicable_employee_value: [],
  is_automatic: 0,
  amount: 0
})
const staffList = ref([])
const isLoading = ref(false)
const creating = ref(false)
const router = useRouter()


const limitText = (count) => {
  return `and ${count} other staff`
}

const createCreditLine = () => {
  if (creating.value) return

  error.value = null

  if (form.value.applicable_employee_kind == null || form.value.amount <= 0) {
    error.value = 'All fields are required'
    return
  }

  if (
    form.value.applicable_employee_kind != 'all' &&
    (form.value.applicable_employee_value == null ||
      !form.value.applicable_employee_value.length)
  ) {
    error.value = 'All fields are required'
    return
  }

  creating.value = true
  let staffIds

  const payload = {
    apply_immediately: form.value.apply_immediately,
    applicable_employee_kind: form.value.applicable_employee_kind,
    is_automatic: form.value.is_automatic,
    amount: form.value.amount
  }

  if (
    form.value.applicable_employee_value &&
    form.value.applicable_employee_value.length
  ) {
    staffIds = form.value.applicable_employee_value.map((m) => m.id)
    payload.applicable_employee_value = staffIds
  }

  axiosInstance
    .post(`/v1/corporates/${props.companyId}/credit-system`, payload)
    .then(async (data) => {
      if (payload.apply_immediately) {
        await axiosInstance.post(
          `/v1/credit-systems/${data.data.id}/executions`,
          payload
        )
        router.push({
          name: 'ShowCreditLine',
          params: {
            companyId: props.companyId
          }
        })
      } else {
        router.push({
          name: 'ShowCreditLine',
          params: {
            companyId: props.companyId
          }
        })
      }
    })
    .catch((err) => {
      error.value = err.toString()
      if (err.response && err.response.data && err.response.data.message) {
        error.value = err.response.data.message
      }
    })
    .finally(() => {
      creating.value = false
    })
}

const asyncFindEmployee = (query) => {
  isLoading.value = true
  axiosInstance
    .get(`/v1/corporates/${props.companyId}/staff`, { params: { query } })
    .then((response) => {
      staffList.value = response.data.data
      isLoading.value = false
    })
    .catch(() => {
      isLoading.value = false
      staffList.value = []
    })
}

const clearAll = () => {
  form.value.applicable_employee_value = []
}

const fullName = ({ fname, lname }) => {
  return `${fname} ${lname}`
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style></style>
